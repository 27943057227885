


































































































































import { Component, Vue } from 'vue-property-decorator'
import {
  apiSystemUpgradeLists,
  apiSystemUpgradeDownloadPkg,
  apiSystemUpgrade
} from '@/api/setting'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
  components: {
    LsPagination
  }
})
export default class SystemUpdate extends Vue {
  /** S Data **/

    // 外层dialog
    outerVisible = false;
    // 第二层dialog
    innerVisible = false;
    // 第三层dialog
    threeVisible = false;
    // 是否二次开发
    isSecondaryDev = true;

    // 加载动画
    loading = true;

    pager: RequestPaging = new RequestPaging();

    // 点击一键更新按钮的版本id
    id = 0;

    timer = 0;
    /** E Data **/

    /** S Methods **/

    // 系统更新列表
    getSystemUpgradeLists () {
      // 分页请求
      this.pager.request({
        callback: apiSystemUpgradeLists
      })
    }

    // 非完整安装包下载
    toPackage (id: number, type: number) {
      if (this.timer) {
        clearTimeout(this.timer)
      }

      this.timer = setTimeout((): void => {
        apiSystemUpgradeDownloadPkg({
          id: id,
          update_type: type // 2:服务端更新包下载，3:pc端更新包下载，4: uni app更新包下载,5:后台前端更新包下载, 6:完整包更新， 9:平台端更新
        }).then((res) => {
          if (type == 6) {
            window.open(res.line, '_blank')
          } else {
            window.location.href = res.line
          }
        })
      }, 500)
    }

    // 一键更新请求
    async systemUpgrade (): Promise<void> {
      await apiSystemUpgrade({
        id: this.id,
        update_type: 1 // 1:一键更新
      }).finally(() => {
        this.threeVisible = false
        this.innerVisible = false
        this.outerVisible = false
      })
      this.getSystemUpgradeLists()
    }

    // 点击一键更新按钮
    onOuterVisible (id: number) {
      this.outerVisible = true
      this.id = id
    }

    // 未做二次开发
    NotSecondaryDev () {
      this.isSecondaryDev = false
      this.innerVisible = true
    }

    // 已做二次开发
    SecondaryDev () {
      this.isSecondaryDev = true
      this.innerVisible = true
    }

    // 点击确定更新
    confirmUpdate () {
      this.threeVisible = true
      this.systemUpgrade()
    }

    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getSystemUpgradeLists()
    }
    /** E Life Cycle **/
}
